import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  id: "common-foot"
};
const _hoisted_2 = {
  id: "common-foot-navList"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "menu-name"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "menu-name"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "menu-name"
};
const _hoisted_9 = {
  class: "menu-icon"
};
const _hoisted_10 = {
  class: "menu-name"
};
const _hoisted_11 = {
  class: "menu-icon"
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  class: "menu-name"
};
import { ref } from "vue";
// 网站导航
import { useRouter } from "vue-router";
export default {
  __name: 'common_foot',
  setup(__props) {
    const menuChosen = ref(null);
    const navList = [
    // About Us
    {
      name: "About Us",
      id: "6",
      isFather: true
    }, {
      name: "Our Story",
      path: "/about/our_story",
      id: "6_1",
      fatherId: "6"
    }, {
      name: "Our Credo",
      path: "/about/our_credo",
      id: "6_2",
      fatherId: "6"
    }, {
      name: "Our Values",
      path: "/about/our_value",
      id: "6_4",
      fatherId: "6"
    }, {
      name: "Our Mission",
      path: "/about/our_mission",
      id: "6_5",
      fatherId: "6"
    }, {
      name: "Our Creations",
      path: "/about/our_creations",
      id: "6_3",
      fatherId: "6"
    },
    // Global Sourcing
    {
      name: "Global Sourcing",
      id: "9",
      isSingle: true,
      path: "/global/index"
    },
    // Career
    {
      name: "Career",
      id: "7",
      isFather: true
    }, {
      name: "What We Do",
      path: "/career/what_we_do",
      id: "7_1",
      fatherId: "7"
    }, {
      name: "Opportunities",
      path: "/career/oppotunities",
      id: "7_2",
      fatherId: "7"
    }, {
      name: "How To Apply",
      path: "/career/how_to_apply",
      id: "7_3",
      fatherId: "7"
    }, {
      name: "Your Own Small Business",
      path: "/career/your_own_small_business",
      id: "7_4",
      fatherId: "7"
    },
    // Social
    {
      name: "Social Influence",
      id: "8",
      isFather: true
    }, {
      name: "Co-communications",
      path: "/social/co_communications",
      id: "8_1",
      fatherId: "8"
    }, {
      name: "Social Responsibilities",
      path: "/social/responsibility",
      id: "8_2",
      fatherId: "8"
    }];

    // 点击导航列表，跳转到对应页面
    const router = useRouter();
    const to = path => {
      router.push(path);
      menuChosen.value = null;
    };
    return (_ctx, _cache) => {
      const _component_Plus = _resolveComponent("Plus");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_Minus = _resolveComponent("Minus");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.filter(el => el.fatherId == menuChosen.value || !el.fatherId), nav => {
        return _openBlock(), _createElementBlock("div", {
          class: "menu",
          key: nav.id
        }, [nav.isSingle ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "menu-simple",
          onClick: $event => to(nav.path)
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(nav.name), 1)], 8, _hoisted_3)) : !nav.isFather && !nav.fatherId ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "menu-simple",
          onClick: $event => to(nav.path)
        }, [_createElementVNode("div", _hoisted_6, _toDisplayString(nav.name), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
          class: "menu-icon"
        }, null, -1))], 8, _hoisted_5)) : nav.isFather && menuChosen.value != nav.id ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "menu-simple",
          onClick: $event => menuChosen.value = nav.id
        }, [_createElementVNode("div", _hoisted_8, _toDisplayString(nav.name), 1), _createElementVNode("div", _hoisted_9, [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Plus)]),
          _: 1
        })])], 8, _hoisted_7)) : nav.isFather && menuChosen.value == nav.id ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "menu-simple",
          onClick: _cache[0] || (_cache[0] = $event => menuChosen.value = null)
        }, [_createElementVNode("div", _hoisted_10, _toDisplayString(nav.name), 1), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Minus)]),
          _: 1
        })])])) : !nav.isFather && nav.fatherId == menuChosen.value ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          onClick: $event => to(nav.path),
          class: "menu-child"
        }, [_createElementVNode("div", _hoisted_13, _toDisplayString(nav.name), 1), _cache[2] || (_cache[2] = _createElementVNode("div", {
          class: "menu-icon"
        }, null, -1))], 8, _hoisted_12)) : _createCommentVNode("", true)]);
      }), 128))])]);
    };
  }
};