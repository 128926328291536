// router/index.js 路由分配+路由守卫
// 导入Vue
import { createRouter, createWebHashHistory } from "vue-router";
// 创建路由的实例对象，定义路由规则，不需要写#
let router = createRouter({
  // 通过history属性，制定路由的工作模式
  history: createWebHashHistory(),
  routes: [
    // 重定向规则
    { path: "/", redirect: "/index" },
    // 路由规则
    {
      path: "/index",
      component: () => import("../src/components/home/home_index.vue"),
      meta: { name: "home_index", requiresAuth: false },
    },

    { path: "/benefit", redirect: "/benefit/index" },
    {
      path: "/benefit/index",
      component: () => import("../src/components/benefit/benefit_index.vue"),
      meta: { name: "benefit_index", requiresAuth: false },
    },

    {
      path: "/benefit/login",
      component: () => import("../src/components/benefit/benefit_login.vue"),
      meta: { name: "benefit_login", requiresAuth: false },
    },

    {
      path: "/benefit/register",
      component: () => import("../src/components/benefit/benefit_register.vue"),
      meta: { name: "benefit_register", requiresAuth: false },
    },

    { path: "/encouragement", redirect: "/encouragement/index" },
    {
      path: "/encouragement/index",
      component: () =>
        import("../src/components/encouragement/encouragement_index.vue"),
      meta: { name: "encourangement_index", requiresAuth: false },
    },

    {
      path: "/encourangement/catagory_page",
      component: () =>
        import(
          "../src/components/encouragement/encouragement_catagory_page.vue"
        ),
      meta: { name: "encourangement_catagory_page", requiresAuth: false },
    },

    {
      path: "/encourangement/all",
      component: () =>
        import("../src/components/encouragement/encouragement_all.vue"),
      meta: { name: "encourangement_all", requiresAuth: false },
    },

    {
      path: "/encouragement/goods/sagol_er_1g",
      component: () =>
        import("../src/components/encouragement/goods/goods_sagol_er_1g.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/sagol_er_4g6",
      component: () =>
        import("../src/components/encouragement/goods/goods_sagol_er_4g6.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/reserve_er",
      component: () =>
        import("../src/components/encouragement/goods/goods_reserve_er.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/grind_er",
      component: () =>
        import("../src/components/encouragement/goods/goods_grind_er.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/brew_er",
      component: () =>
        import("../src/components/encouragement/goods/goods_brew_er.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/adas_2g",
      component: () =>
        import("../src/components/encouragement/goods/goods_adas_2g.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },
    {
      path: "/encouragement/goods/adas_4g6",
      component: () =>
        import("../src/components/encouragement/goods/goods_adas_4g6.vue"),
      meta: { name: "encourangement_individual", requiresAuth: false },
    },

    { path: "/daily", redirect: "/daily/index" },
    {
      path: "/daily/index",
      component: () => import("../src/components/daily/daily_index.vue"),
      meta: { name: "daily_index", requiresAuth: false },
    },

    {
      path: "/daily/series",
      component: () => import("../src/components/daily/daily_series.vue"),
      meta: { name: "daily_series", requiresAuth: false },
    },

    {
      path: "/daily/play",
      component: () => import("../src/components/daily/daily_play.vue"),
      meta: { name: "daily_play", requiresAuth: false },
    },

    // 重定向规则
    { path: "/network", redirect: "/network/global" },
    { path: "/network/index", redirect: "/network/global" },

    {
      path: "/network/why_eyesunrose",
      component: () =>
        import("../src/components/network/network_why_eyesunrose.vue"),
      meta: { name: "network_why_eyesunrose", requiresAuth: false },
    },

    // efficient, idea, place, ready, support, training
    {
      path: "/network/why_eyesunrose/efficient",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_efficient.vue"),
      meta: { name: "n_we_efficient", requiresAuth: false },
    },
    {
      path: "/network/why_eyesunrose/idea",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_idea.vue"),
      meta: { name: "n_we_idea", requiresAuth: false },
    },
    {
      path: "/network/why_eyesunrose/place",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_place.vue"),
      meta: { name: "n_we_place", requiresAuth: false },
    },
    {
      path: "/network/why_eyesunrose/ready",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_ready.vue"),
      meta: { name: "n_we_ready", requiresAuth: false },
    },
    {
      path: "/network/why_eyesunrose/support",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_support.vue"),
      meta: { name: "n_we_support", requiresAuth: false },
    },
    {
      path: "/network/why_eyesunrose/training",
      component: () =>
        import("../src/components/network/why_eyesunrose/n_we_training.vue"),
      meta: { name: "n_we_training", requiresAuth: false },
    },

    {
      path: "/network/apply_to_be_franchisee",
      component: () =>
        import("../src/components/network/network_apply_to_be_franchisee.vue"),
      meta: { name: "network_apply_to_be_franchisee", requiresAuth: false },
    },

    {
      path: "/network/ask_your_questions",
      component: () =>
        import("../src/components/network/network_ask_your_questions.vue"),
      meta: { name: "network_ask_your_questions", requiresAuth: false },
    },

    {
      path: "/network/franchisee_highlights",
      component: () =>
        import("../src/components/network/network_franchisee_highlights.vue"),
      meta: { name: "network_franchisee_highlights", requiresAuth: false },
    },

    // 重定向规则
    { path: "/network/application/form", redirect: "/network/application" },
    { path: "/network/application/index", redirect: "/network/application" },
    {
      path: "/network/application/newform",
      redirect: "/network/application/new",
    },
    {
      path: "/network/application/formnew",
      redirect: "/network/application/new",
    },
    {
      path: "/network/application/form/new",
      redirect: "/network/application/new",
    },

    {
      path: "/network/application",
      component: () =>
        import("../src/components/network/network_application_forms.vue"),
      meta: { name: "network_application_forms", requiresAuth: false },
    },
    {
      path: "/network/application/new",
      component: () =>
        import("../src/components/network/application/n_a_newform.vue"),
      meta: { name: "n_a_new", requiresAuth: false },
    },
    {
      path: "/network/application/sumit",
      component: () =>
        import("../src/components/network/application/n_a_sumit.vue"),
      meta: { name: "n_a_sumit", requiresAuth: false },
    },
    {
      path: "/network/application/track",
      component: () =>
        import("../src/components/network/application/n_a_track.vue"),
      meta: { name: "n_a_track", requiresAuth: false },
    },

    {
      path: "/network/application/backend",
      component: () =>
        import("../src/components/network/application/n_a_backend.vue"),
      meta: { name: "n_a_backend", requiresAuth: false },
    },

    {
      path: "/network/global",
      component: () => import("../src/components/network/network_global.vue"),
      meta: { name: "network_global", requiresAuth: false },
    },

    { path: "/users/index", redirect: "/users/profile" },
    { path: "/users", redirect: "/users/profile" },
    {
      path: "/users/profile",
      component: () => import("../src/components/users/users_profile.vue"),
      meta: { name: "users_profile", requiresAuth: false },
    },

    {
      path: "/users/my_cart",
      component: () => import("../src/components/users/users_my_cart.vue"),
      meta: { name: "users_my_cart", requiresAuth: false },
    },

    {
      path: "/users/my_order",
      component: () => import("../src/components/users/users_my_order.vue"),
      meta: { name: "users_my_order", requiresAuth: false },
    },

    {
      path: "/users/wish_list",
      component: () => import("../src/components/users/users_wish_list.vue"),
      meta: { name: "users_wish_list", requiresAuth: false },
    },

    {
      path: "/users/messages",
      component: () => import("../src/components/users/users_messages.vue"),
      meta: { name: "users_messages", requiresAuth: false },
    },

    {
      path: "/users/address_lists",
      component: () =>
        import("../src/components/users/users_address_lists.vue"),
      meta: { name: "users_address_lists", requiresAuth: false },
    },

    {
      path: "/purchase/initial_check_out",
      component: () =>
        import("../src/components/purchase/purchase_initial_check_out.vue"),
      meta: { name: "purchase_initial_check_out", requiresAuth: false },
    },

    {
      path: "/purchase/gift_option",
      component: () =>
        import("../src/components/purchase/purchase_gift_option.vue"),
      meta: { name: "purchase_gift_option", requiresAuth: false },
    },

    {
      path: "/purchase/check_out",
      component: () =>
        import("../src/components/purchase/purchase_check_out.vue"),
      meta: { name: "purchase_check_out", requiresAuth: false },
    },

    {
      path: "/purchase/payment_succeed",
      component: () =>
        import("../src/components/purchase/purchase_payment_succeed.vue"),
      meta: { name: "purchase_payment_succeed", requiresAuth: false },
    },

    {
      path: "/purchase/delivery",
      component: () =>
        import("../src/components/purchase/purchase_delivery.vue"),
      meta: { name: "purchase_delivery", requiresAuth: false },
    },

    { path: "/about", redirect: "/about/our_story" },
    { path: "/about/index", redirect: "/about/our_story" },
    {
      path: "/about/our_story",
      component: () => import("../src/components/about/about_our_story.vue"),
      meta: { name: "about_our_story", requiresAuth: false },
    },

    {
      path: "/about/our_credo",
      component: () => import("../src/components/about/about_our_credo.vue"),
      meta: { name: "about_our_credo", requiresAuth: false },
    },

    {
      path: "/about/our_mission",
      component: () => import("../src/components/about/about_our_mission.vue"),
      meta: { name: "about_our_mission", requiresAuth: false },
    },

    {
      path: "/about/our_value",
      component: () => import("../src/components/about/about_our_value.vue"),
      meta: { name: "about_our_value", requiresAuth: false },
    },

    {
      path: "/about/our_creations",
      component: () =>
        import("../src/components/about/about_our_creations.vue"),
      meta: { name: "about_our_creations", requiresAuth: false },
    },

    {
      path: "/about/product_faq",
      component: () => import("../src/components/about/about_product_faq.vue"),
      meta: { name: "about_product_faq", requiresAuth: false },
    },

    {
      path: "/career/how_to_apply",
      component: () =>
        import("../src/components/career/career_how_to_apply.vue"),
      meta: { name: "career_how_to_apply", requiresAuth: false },
    },

    {
      path: "/career/oppotunities",
      component: () =>
        import("../src/components/career/career_oppotunities.vue"),
      meta: { name: "career_oppotunities", requiresAuth: false },
    },

    {
      path: "/career/what_we_do",
      component: () => import("../src/components/career/career_what_we_do.vue"),
      meta: { name: "career_what_we_do", requiresAuth: false },
    },

    {
      path: "/career/your_own_small_business",
      component: () =>
        import("../src/components/career/career_your_own_small_business.vue"),
      meta: { name: "career_your_own_small_business", requiresAuth: false },
    },

    {
      path: "/social/co_communications",
      component: () =>
        import("../src/components/social/social_co_communications.vue"),
      meta: { name: "social_co_communications", requiresAuth: false },
    },

    {
      path: "/social/responsibility",
      component: () =>
        import("../src/components/social/social_responsibility.vue"),
      meta: { name: "social_responsibility", requiresAuth: false },
    },

    // 重定向规则
    { path: "/global", redirect: "/global/index" },
    { path: "/global/home", redirect: "/global/index" },
    {
      path: "/global/index",
      component: () => import("../src/components/global/global_index.vue"),
      meta: { name: "global_index", requiresAuth: false },
    },
    {
      path: "/global/saffron",
      component: () => import("../src/components/global/global_saffron.vue"),
      meta: { name: "global_saffron", requiresAuth: false },
    },
    {
      path: "/global/fig",
      component: () => import("../src/components/global/global_fig.vue"),
      meta: { name: "global_fig", requiresAuth: false },
    },
    {
      path: "/global/pistachio",
      component: () => import("../src/components/global/global_pistachio.vue"),
      meta: { name: "global_pistachio", requiresAuth: false },
    },
    {
      path: "/global/order",
      component: () => import("../src/components/global/global_order.vue"),
      meta: { name: "global_order", requiresAuth: false },
    },
  ],
});

// 对外共享路由的实例对象
export default router;
