import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, Transition as _Transition } from "vue";
import _imports_0 from '../../../public/img/Navigator/navIcon.png';
import _imports_1 from '../../../public/img/Navigator/eyesunrose.png';
const _hoisted_1 = {
  id: "common-nav"
};
const _hoisted_2 = {
  key: 0,
  class: "user-panel-ul"
};
const _hoisted_3 = {
  class: "user-panel-center"
};
const _hoisted_4 = {
  class: "icon"
};
const _hoisted_5 = {
  class: "icon"
};
const _hoisted_6 = {
  class: "text"
};
const _hoisted_7 = {
  class: "icon"
};
const _hoisted_8 = {
  class: "icon"
};
const _hoisted_9 = {
  class: "icon"
};
const _hoisted_10 = {
  key: 1,
  class: "user-panel-ul"
};
const _hoisted_11 = {
  class: "icon"
};
const _hoisted_12 = {
  class: "text"
};
const _hoisted_13 = {
  class: "icon"
};
const _hoisted_14 = {
  class: "icon"
};
const _hoisted_15 = {
  key: 0,
  id: "common-nav-navList"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  class: "menu-simple-name"
};
const _hoisted_18 = ["onClick"];
const _hoisted_19 = {
  class: "menu-father-icon"
};
const _hoisted_20 = {
  class: "menu-simple-name"
};
const _hoisted_21 = {
  class: "menu-father-icon"
};
const _hoisted_22 = {
  class: "menu-simple-name"
};
const _hoisted_23 = ["onClick"];
const _hoisted_24 = {
  class: "menu-child-name"
};
import { consoleDebug } from "@/utils";
import { ref } from "vue";
import iconWalkin from "./icon_walkin.vue";
import iconPeople from "./icon_people.vue";
import iconCart from "./icon_cart.vue";
import iconHeart from "./icon_heart.vue";
import iconRecipe from "./icon_recipe.vue";
import iconExit from "./icon_exit.vue";
import iconProfile from "./icon_profile.vue";
import iconNavMenu from "./icon_nav_menu.vue";
// 导航列表是否显示
import { useRouter } from "vue-router";
import axios from "axios";
// 判断用户是否已登录。后期用后端代替
import { getCartNum } from "./func_cart.js";
export default {
  __name: 'common_nav',
  setup(__props) {
    const isNavListShow = ref(false);
    // 网站导航
    const menuChosen = ref(null);
    const navList = [{
      name: "Encouragements",
      path: "/encouragement",
      id: "1"
    }, {
      name: "Daily Feeds",
      path: "/daily",
      id: "2"
    }, {
      name: "Network",
      path: "/network",
      id: "3"
    }, {
      name: "Benefit Club",
      path: "/benefit/index",
      id: "5"
    }];

    // 点击导航列表，跳转到对应页面
    const isUserListShow = ref(false);
    const router = useRouter();
    const to = path => {
      router.push(path);
      menuChosen.value = null;
      isNavListShow.value = false;
      isUserListShow.value = false;
    };
    const isLogin = ref(false);
    const lastName = ref("");
    const firstName = ref("");
    // 用后端判断用户是否已登录
    const checkIsLogin = async () => {
      // 定义headers
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      };
      // axios调用/regesting/check_token, post
      axios.post("/registing/check_token", {}, {
        headers: headers
      }).then(res => {
        if (res.data.status == "OK") {
          consoleDebug("checkIsLogin", res.data);
          isLogin.value = true;
          lastName.value = res.data.last_name;
          firstName.value = res.data.first_name;
          countCart();
        } else if (res.data.message == "login expired") {
          consoleDebug("checkIsLogin", res.data);
          isLogin.value = false;
        } else if (res.data.message == "not login") {
          isLogin.value = false;
        } else if (res.data.message == "invalid login token") {
          isLogin.value = false;
        }
      }).catch(err => {
        consoleDebug("checkIsLogin", err);
        // 如果是401错误，则isLogin.value = false
        if (err && err.status == 401) {
          isLogin.value = false;
        } else if (err.response && err.response.status == 401) {
          isLogin.value = false;
        }
      });
    };
    // 在页面加载时检查用户是否已登录
    checkIsLogin();

    // 在内存中获得购物车中的商品数量
    const cartNum = ref(0);
    const countCart = () => {
      cartNum.value = getCartNum();
    };
    countCart();

    // 退出登录
    const logout = () => {
      // 删除localStorage中的token
      localStorage.removeItem("token");
      consoleDebug("logout", "localStorage.removeItem('token')");
      alert("logout success");
    };
    return (_ctx, _cache) => {
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_Close = _resolveComponent("Close");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_ArrowRight = _resolveComponent("ArrowRight");
      const _component_ArrowDown = _resolveComponent("ArrowDown");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        id: "common-nav-icon",
        class: "img",
        onClick: _cache[0] || (_cache[0] = $event => isNavListShow.value = 'true')
      }, _cache[12] || (_cache[12] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "logo"
      }, null, -1)])), _createElementVNode("div", {
        id: "common-nav-menu",
        onClick: _cache[1] || (_cache[1] = $event => isNavListShow.value = 'true')
      }, [_createVNode(iconNavMenu)]), _cache[19] || (_cache[19] = _createElementVNode("div", {
        id: "common-nav-bigname"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _cache[20] || (_cache[20] = _createElementVNode("div", {
        id: "common-nav-slogan"
      }, "Sincere Health, Unique Style", -1)), _createElementVNode("div", {
        id: "common-nav-user",
        onClick: checkIsLogin
      }, [_createVNode(_component_el_popover, {
        trigger: "click",
        width: 80,
        effect: "dark",
        visible: isUserListShow.value,
        "onUpdate:visible": _cache[9] || (_cache[9] = $event => isUserListShow.value = $event),
        ref: "popover"
      }, {
        reference: _withCtx(() => [_createVNode(iconProfile)]),
        default: _withCtx(() => [isLogin.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, " Hi, " + _toDisplayString(firstName.value) + " " + _toDisplayString(lastName.value), 1), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[2] || (_cache[2] = $event => to('/users/profile'))
        }, [_createElementVNode("div", _hoisted_4, [_createVNode(iconPeople)]), _cache[13] || (_cache[13] = _createElementVNode("div", {
          class: "text"
        }, "My Account", -1))]), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[3] || (_cache[3] = $event => to('/users/my_cart'))
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(iconCart)]), _createElementVNode("div", _hoisted_6, "My Cart (" + _toDisplayString(cartNum.value) + ")", 1)]), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[4] || (_cache[4] = $event => to('/users/wish_list'))
        }, [_createElementVNode("div", _hoisted_7, [_createVNode(iconHeart)]), _cache[14] || (_cache[14] = _createElementVNode("div", {
          class: "text"
        }, "Wish List", -1))]), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[5] || (_cache[5] = $event => to('/users/my_order'))
        }, [_createElementVNode("div", _hoisted_8, [_createVNode(iconRecipe)]), _cache[15] || (_cache[15] = _createElementVNode("div", {
          class: "text"
        }, "My Order", -1))]), _createElementVNode("div", {
          class: "user-panel",
          onClick: logout
        }, [_createElementVNode("div", _hoisted_9, [_createVNode(iconExit)]), _cache[16] || (_cache[16] = _createElementVNode("div", {
          class: "text"
        }, "Login out", -1))])])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[6] || (_cache[6] = $event => to('/users/my_cart'))
        }, [_createElementVNode("div", _hoisted_11, [_createVNode(iconCart)]), _createElementVNode("div", _hoisted_12, "My Cart (" + _toDisplayString(cartNum.value) + ")", 1)]), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[7] || (_cache[7] = $event => to('/users/wish_list'))
        }, [_createElementVNode("div", _hoisted_13, [_createVNode(iconHeart)]), _cache[17] || (_cache[17] = _createElementVNode("div", {
          class: "text"
        }, "Wish List", -1))]), _createElementVNode("div", {
          class: "user-panel",
          onClick: _cache[8] || (_cache[8] = $event => to('/benefit/login'))
        }, [_createElementVNode("div", _hoisted_14, [_createVNode(iconWalkin)]), _cache[18] || (_cache[18] = _createElementVNode("div", {
          class: "text"
        }, "Login", -1))])]))]),
        _: 1
      }, 8, ["visible"])]), _createVNode(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(() => [isNavListShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", {
          id: "common-nav-exit",
          onClick: _cache[10] || (_cache[10] = $event => isNavListShow.value = false)
        }, [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Close)]),
          _: 1
        })]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.filter(el => el.fatherId == menuChosen.value || !el.fatherId), nav => {
          return _openBlock(), _createElementBlock("div", {
            class: "menu",
            key: nav.id
          }, [!nav.isFather && !nav.fatherId ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "menu-simple",
            onClick: $event => to(nav.path)
          }, [_createElementVNode("div", _hoisted_17, _toDisplayString(nav.name), 1)], 8, _hoisted_16)) : nav.isFather && menuChosen.value != nav.id ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "menu-simple",
            onClick: $event => menuChosen.value = nav.id
          }, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
            _: 1
          })]), _createElementVNode("div", _hoisted_20, _toDisplayString(nav.name), 1)], 8, _hoisted_18)) : nav.isFather && menuChosen.value == nav.id ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "menu-simple",
            onClick: _cache[11] || (_cache[11] = $event => menuChosen.value = null)
          }, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_ArrowDown)]),
            _: 1
          })]), _createElementVNode("div", _hoisted_22, _toDisplayString(nav.name), 1)])) : !nav.isFather && nav.fatherId == menuChosen.value ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            onClick: $event => to(nav.path)
          }, [_createElementVNode("div", _hoisted_24, _toDisplayString(nav.name), 1)], 8, _hoisted_23)) : _createCommentVNode("", true)]);
        }), 128))])) : _createCommentVNode("", true)]),
        _: 1
      })]);
    };
  }
};