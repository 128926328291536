import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("path", {
    class: "st0",
    d: "M0,25.6v119.467h512V25.6H0z M110.933,110.916h-51.2v-51.2h51.2V110.916z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M0,315.733h512V196.267H0V315.733z M59.733,230.383h51.2v51.2h-51.2V230.383z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M0,486.4h512V366.933H0V486.4z M59.733,401.05h51.2v51.2h-51.2V401.05z"
  })], -1)]));
}