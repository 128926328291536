import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("polygon", {
    class: "st0",
    points: "434.162,293.382 434.162,493.862 308.321,493.862 308.321,368.583 203.682,368.583 203.682,493.862 \r\n\t\t77.841,493.862 77.841,293.382 256.002,153.862 \t"
  }), _createElementVNode("polygon", {
    class: "st0",
    points: "0,242.682 256,38.93 512,242.682 482.21,285.764 256,105.722 29.79,285.764 \t"
  }), _createElementVNode("polygon", {
    class: "st0",
    points: "439.853,18.138 439.853,148.538 376.573,98.138 376.573,18.138 \t"
  })], -1)]));
}