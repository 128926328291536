// 判断购物车是否存在，如果是的话返回购物车数据，否则返回空数组
export const getCart = () => {
  if (localStorage.getItem("cart")) {
    return JSON.parse(localStorage.getItem("cart"));
  } else {
    return [];
  }
};

// 获取购物车中商品数量
export const getCartNum = () => {
  const cartData = getCart();
  return cartData.length;
};

// 判断购物车是否存在特定商品
export const isExist = (skd_id) => {
  const cartData = getCart();
  let result = false;
  cartData.forEach((item) => {
    if (item.skd_id == skd_id) {
      result = true;
    }
  });
  return result;
};

// 从购物车中删除商品
export const deleteGood = (skd_id) => {
  let cartData = JSON.parse(localStorage.getItem("cart"));
  cartData = cartData.filter((item) => item.skd_id != skd_id);
  console.log(cartData);
  localStorage.setItem("cart", JSON.stringify(cartData));
};

// 更改特定商品的数量
export const changeGoodNum = (skd_id, num) => {
  let cartData = JSON.parse(localStorage.getItem("cart"));
  cartData.forEach((item) => {
    if (item.skd_id == skd_id) {
      item.amount = num;
    }
  });
  localStorage.setItem("cart", JSON.stringify(cartData));
};

// 添加新商品到购物车
export const addNewGoodToCart = (datum) => {
  // 判断本地内存中是否有cart变量，如果有则取出，没有则创建
  let cartData = getCart();
  if (!isExist(datum.skd_id)) {
    // 如果购物车中没有该商品，则添加
    cartData.push({ ...datum });
  } else {
    // 如果购物车中已经有该商品，则数量加1
    cartData.forEach((item) => {
      if (item.skd_id == datum.skd_id) {
        item.amount += datum.amount;
      }
    });
  }
  localStorage.setItem("cart", JSON.stringify(cartData));
};

// 清空购物车
export const clearCart = () => {
  localStorage.removeItem("cart");
};
